import { Observable, fromEvent } from 'rxjs';
import { first } from 'rxjs/operators';

/**
 * BridgeChannelManager definition
 */
export default class BridgeChannelManager {
  constructor(bridge) {
    this.componentConnector = bridge.ComponentConnector;
    this.privateChannels = new Set();
  }

  /**
   * Returns the name of the application context channel.
   */
  getAppContextChannelName() {
    return '__bridge_app';
  }

  /**
   * Returns the name of the channels that has cancellations of back navigations.
   */
  getCancelledBackNavigationChannelName() {
    return '__bridge_cancelled_back_navigation';
  }

 /**
   * Returns the name of the channels that has intercepted navigations.
   */
  getInterceptedNavigationChannelName() {
    return '__bridge_intercepted_navigation';
  }

  /**
   * Returns the prefix for private channels.
   */
  getPrivateChannelPrefix() {
    return '__bridge_page_';
  }

  /**
   * Returns the prefix for event channels.
   */
  getEventChannelPrefix() {
    return '__bridge_evt_';
  }

  /**
   * Returns the prefix for generic channels.
   */
  getBridgeChannelPrefix() {
    return '__bridge_ch_';
  }

  /**
   * Returns the prefix for generic channels.
   */
  getPostMessageChannelPrefix() {
    return '__bridge_post_message_';
  }

  /**
   * Gets a channel that is for exclusive use of the bridge.
   * If the channel doesn't exist, it creates one channel with.
   *
   * @param name of the channel to retrieve/create
   *
   * @return {Channel}
   *
   */
  getBridgeChannel(channelName) {
    return this.componentConnector.manager.get(this.getBridgeChannelPrefix() +  channelName);
  }

  getIdleCallbackChannel() {
    const iddleChannel$ = Observable.create(observer => observer.next(true));

    return iddleChannel$.pipe(first());
  }

  /**
   * Gets the application context channel.
   *
   * @return {Channel}
   */
  getAppContextChannel() {
    return this.componentConnector.manager.get(this.getAppContextChannelName());
  }

  /**
   * Gets the cancelled back navigations channel.
   *
   * @return {Channel}
   */
  getCancelledBackNavigationChannel() {
    return this.componentConnector.manager.get(this.getCancelledBackNavigationChannelName());
  }

  /**
   * Gets the intercepted navigations channel.
   *
   * @return {Channel}
   */
   getInterceptedNavigationChannel() {
    return this.componentConnector.manager.get(this.getInterceptedNavigationChannelName());
  }

  /**
   * Gets the private channel that corresponds to a page.
   *
   * @param  {String} pageName
   *
   * @return {Channel}
   */
  getPrivate(pageName) {
    let newName = this.getPrivateChannelPrefix() + pageName;
    let channel = this.componentConnector.manager.get(newName);
    this.privateChannels.add(newName);
    return channel;
  }

  getPostMessageChannel(eventName){
    let newName = this.getPostMessageChannelPrefix() + eventName;

    return this.componentConnector.manager.get(newName);
  }

  /**
   * Creates and initializes the application context channel.
   *
   */
  initAppContextChannel() {
    this.getAppContextChannel();
  }

  /**
   * Creates and initializes the cancelled back navigation channel.
   *
   */
  initCancelledBackNavigationChannel() {
    this.getCancelledBackNavigationChannel();
  }

  /**
   * Initializes the private chanel for the given page.
   *
   * @param {String} pageName
   *
   */
  initPrivateChannel(oldPageName, newPageName) {
    this.publishPrivatePageStatus(newPageName, true);

    if (oldPageName) {
      this.publishPrivatePageStatus(oldPageName, false);
    }
  }

  /**
   * Publish the status of a page in its private channel
   *
   * @param {String} pageName
   * @param {Boolean} status is true if the page is active, and false if it is inactive
   */
  publishPrivatePageStatus(pageName, status) {
    const channel = this.getPrivate(pageName);
    const evt = this.componentConnector.createEvent('page-load', status);

    channel.next(evt);
  }

  /**
   * Updates the application context.
   * Puts in context the information of the current page and previous active page.
   *
   * @param {String} oldPage was the previous current page
   * @param {String} newPage is the page that becomes the current page
   * @param {Object} appContext an object used by the application to store its current context
   * @param {Route} currentRoute has the details about the route
   *
   */
  updateAppContext(oldPage, newPage, appContext, currentRoute) {
      const evt = this.componentConnector.createEvent('app-context', {
      currentPage: newPage,
      fromPage: oldPage,
      interceptorContext: appContext,
      currentRoute
    });

    this.getAppContextChannel().next(evt);
  }

  updateBridgeChannels(oldPage, newPage, appContext, currentRoute) {
    this.updateAppContext(oldPage, newPage, appContext, currentRoute);
    this.initPrivateChannel(oldPage, newPage);
  }

  publishCancelledBackNavigation(navigation) {
    const evt = this.componentConnector.createEvent('back-nav-cancelled', navigation);

    this.getCancelledBackNavigationChannel().next(evt);
  }

  publishInterceptedNavigation(navigation) {
    const evt = this.componentConnector.createEvent('intercepted-navigation', navigation);

    this.getInterceptedNavigationChannel().next(evt);
  }

  /**
   * returns true if the given name matches a private channel's name
   *
   * @param {String} name
   *
   * @return {Boolean}
   */
  isPrivateChannel(name) {
    return name.indexOf(this.getPrivateChannelPrefix()) === 0;
  }

  /**
   * returns true if there's a private channel with the given name
   *
   * @param {String} name
   *
   * @return {Boolean}
   */
  isActivePrivateChannel(name) {
    return this.privateChannels.has(name);
  }

  /**
   * resets all channels, including the private channels.
   * It removes all observers and publications.
   *
   */
  resetBridgeChannels(mainNode, cleanPrivateChannels) {
    let bridgeChannels = Object.keys(this.componentConnector.manager.channels);

    bridgeChannels.forEach((chnlName) => {
      let chnl = this.componentConnector.manager.get(chnlName);
      chnl.clean();
      chnl.unsubscribeAll();
    });

    this.componentConnector.unregisterComponent(mainNode, cleanPrivateChannels);
    this.componentConnector.unregisterAllSubscriptors(cleanPrivateChannels);
    this.componentConnector.manager.cleanAllChannels();
  }

  getCCSubscriptions(crossContainerId) {
    const crossComponents = Array
                        .from(this.componentConnector.subscriptors.values())
                        .filter(c => c.node.parentNode?.id===crossContainerId);

    const outConnections = crossComponents
                            .map(c => c.publications._subscriptions
                                          ?.map(s => {
                                                  return {
                                                    channel: s.channelName,
                                                    bind: s.eventName,
                                                    component: c.node,
                                                    options: s.options
                                                  }}))
                            .filter(c => c!==undefined)
                            .reduce((acc, cnxs)=>acc.concat(cnxs), []);

    const inConnections = crossComponents
                            .map(c => c.subscriptions
                                          ?.map(s => {
                                            return {
                                              channel: s.channel.name,
                                              bind: s.bind,
                                              component: c.node
                                            }}))
                            .filter(c => c!==undefined)
                            .reduce((acc, cnxs)=>acc.concat(cnxs), []);

    return {inConnections, outConnections}
  }

  initEventChannels(node, externalEvents) {
    externalEvents.forEach((eventName) => {
      let prefix = this.getEventChannelPrefix();
      let channelName = prefix + eventName;
      let channel = this.componentConnector.manager.get(channelName);
      let source = fromEvent(node, eventName);
      source.subscribe((event) => channel.next(event));
    });
  }

  subscribeToEvent(node, eventName, callback) {
    let prefix = this.getEventChannelPrefix();
    let channelName = prefix + eventName;
    let subscriptor = this.componentConnector.getSubscriptor(node);
    let channel = this.componentConnector.manager.get(channelName);
    callback.node = node;
    subscriptor.subscribe(channel, callback);
  }
}
